import React, { forwardRef } from "react";
import "./projectsss.css";
import beadu from "./beadu.png";
import mechhep from "./mechhep.png";
import news from "./news.png";


const Project = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="py-4">
      <h1 className="text-light" style={{ paddingLeft: "7%" }}>
        Projects
      </h1>
      <div className="text-center row container px-4 ppprr py-4">
        <div className="text-center col-lg-4 col-sm-6 im1 mb-4 bead">
          <a href="https://beadu.me/" target="_blank">
            <img src={beadu} className="rounded" alt="..." />
          </a>
        </div>
        <div className="text-center col-lg-4 col-sm-6 im1 mb-4 mechh">
          <a href="https://mechhelp.in/lander" target="_blank">
            <img src={mechhep} className="rounded" alt="..." />
          </a>
        </div>
        <div className="text-center col-lg-4 col-sm-6 im1 py-2 mb-4">
          <a href="https://newsapp-indol-xi.vercel.app/" target="_blank">
            <img src={news} className="rounded" alt="..." />
          </a>
        </div>
      </div>
    </div>
  );
});

export default Project;
