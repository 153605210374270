import React,{ useRef } from 'react'
import './animation.css';

export default function About() {
  const aboutRef = useRef(null);
  return (
    <div className='text-light container abt'>        
            A dedicated web developer driven by a passion for crafting engaging online experiences. With a keen eye for design and a knack for coding, 
            I specialize in transforming concepts into polished websites and applications. Let's collaborate to turn your digital dreams into reality!
    </div>
  )
}
