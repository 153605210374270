import React, { forwardRef } from "react";
import "./skillsss.css";
import htm from './htm.png';
import css from './css.png';
import js from './js.png';
import react from './react.png'
import bootsratp from './bootstrap.png'

const Skills = forwardRef((props, ref) => {
  return (
    <div className="m-0 p-0 " ref={ref}>
      <h1
        className="text-light"
        style={{ paddingLeft: "5%", marginTop: "0px" }}
        id="head"
      >
        Skills
      </h1>
      <div className="container py-2 text-center">
        <div className="skill">
          <img src={htm} alt="HTML" className="skill-icon html" />
          <div className="skill-label">HTML</div>
        </div>

        <div className="skill">
          <img src={css} alt="CSS" className="skill-icon css" />
          <div className="skill-label">CSS</div>
        </div>

        <div className="skill">
          <img
            src={js}
            alt="JavaScript"
            className="skill-icon js"
          />
          <div className="skill-label">JavaScript</div>
        </div>

        <div className="skill">
          <img
            src={bootsratp}
            alt="bootsratp"
            className="skill-icon js"
          />
          <div className="skill-label">bootsratp</div>
        </div>

        <div className="skill">
          <img src={react} alt="React" className="skill-icon react" />
          <div className="skill-label">React</div>
        </div>
      </div>
    </div>
  );
});

export default Skills;
