import React, { useRef, forwardRef, useState } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = forwardRef((props, ref) => {
  const form = useRef();
  const [messageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_r1ybxss', 'template_alwmge5', form.current, {
        publicKey: 'xtwrhRBp2zZPq5la6',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setMessageSent(true);
          // Reset the form after sending the email
          if (form.current) {
            form.current.reset();
          }
          // Hide the flash message after a few seconds
          setTimeout(() => {
            setMessageSent(false);
          }, 5000);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="text-light py-4" ref={ref}>
      <h1 style={{ paddingLeft: "5%" }}>Contact</h1>
      {messageSent && (
        <div className="alert alert-success fixed-top" role="alert" >
          Message sent successfully!
        </div>
      )}
      <div className="container row text-center">
        <form ref={form} onSubmit={sendEmail}>
          <div className="col my-2" style={{ fontSize: "100%" }}>
            <label className="text-light mx-2">Name</label>
            <input type="text" name="from_name" />
          </div>
          <div className="col my-2" style={{ fontSize: "100%" }}>
            <label className="text-light mx-2">Email</label>
            <input type="email" name="user_email" />
          </div>
          <div className="col my-3" style={{ fontSize: "100%", paddingRight: "4%" }}>
            <label className="text-light pd-2px mx-2" style={{ bottom: "40px", position: "relative", fontSize: "100%" }}>Message</label>
            <textarea name="message" />
          </div>
          <input type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
});

export default ContactUs;
