import React from 'react'
import './projectsss.css';

export default function Footer() {
  return (
    <div className='text-center py-2'>
        <footer>
        <div className="footer-container text-light">

          <div className="footer-social">
            <div className='container text-center'>
                <div className="row my-2">
                    <div className='col-3 col-md ic'><a href="https://github.com/guptadj" target="_blank"><i className="fab fa-github fs-1" /></a></div>
                    <div className='col-3 col-md ic'><a href="https://wa.me/9326081281" target="_blank"><i className="fab fa-whatsapp fs-1" /></a></div>
                    <div className='col-3 col-md ic'><a href="https://www.instagram.com/divyanshu9861" target="_blank"><i className="fab fa-instagram fs-1" /></a></div>
                    <div className='col-3 col-md ic'><a href="https://www.linkedin.com/in/divyanshu-gupta-448681229" target="_blank"><i className="fab fa-linkedin-in fs-1" /></a></div>
                </div>
            </div>
          </div>
        </div>
        <div className="footer-info text-light mt-6 my-3">
          <p>Designed and Developed by <a href="#" target="_blank">Divyanshu Gupta</a></p>
        </div>
      </footer>
    </div>
  )
}
