import React from 'react';
import './navbaar.css'
import propic from './propic.jpg'


class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeRef: null,
      prevScrollPos: window.pageYOffset,
      visible: true
    };
  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { prevScrollPos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos;

    this.setState({
      prevScrollPos: currentScrollPos,
      visible
    });
  };

  scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.setState({ activeRef: ref });
    }
  };

  handleNavClick = (ref) => (event) => {
    event.preventDefault();
    this.scrollToRef(ref);
  };

  render() {
    const { skillsRef, projectRef, contactRef } = this.props;
    const { activeRef, visible } = this.state;
    return (
      <nav className={`navbar navbar-expand-lg bg-dark ${visible ? 'sticky-top' : 'sticky-hidden'}`}>
        <div className="container-fluid">
        <a className="navbar-brand p-0 m-0" href="#">
          <img src={propic} alt="Icon" className="icon-image" style={{width:"40px" , borderRadius:"50%", padding:"0px", margin:"0px"}}/>
        </a>
          <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon x" />
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className={`nav-link text-light ${activeRef === skillsRef ? 'active' : ''}`} href="#" onClick={this.handleNavClick(skillsRef)}>Skills</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link text-light ${activeRef === projectRef ? 'active' : ''}`} href="#" onClick={this.handleNavClick(projectRef)}>Projects</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link text-light ${activeRef === contactRef ? 'active' : ''}`} href="#" onClick={this.handleNavClick(contactRef)}>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
