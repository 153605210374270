import React from 'react';
import resumePDF from './resume.pdf';

class ResumeDownloadButton extends React.Component {
  divStyle = {
    color: 'white',
    backgroundColor: 'transparent',
    fontSize: '20px',
    fontFamily: '"Inter Tight", "sans-serif"',
    fontWeight: 'bold',
    border: '2px solid white',
    textShadow: '1px 1px 2px white',
    position: 'relative', 
    bottom: '5px',
    right:'5%'
    
  };

  downloadResume = () => {
    const link = document.createElement('a');
    link.href = resumePDF;
    link.setAttribute('download', 'resume.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <div className="container text-center my-2">
        <button style={this.divStyle} onClick={this.downloadResume}>
          Download Resume
        </button>
      </div>
    );
  }
}

export default ResumeDownloadButton;
