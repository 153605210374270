  // App.js

  import React, { useRef, useEffect } from 'react';
  import './App.css';
  import Navbar from './component/navbar';
  import { ContactUs } from './component/email';
  import Animation from './component/img';
  import Footer from './component/footer';
  import Project from './component/project';
  import Skills from './component/skills';
  import ResumeDownloadButton from './component/resume';
  import { Analytics } from "@vercel/analytics/react"
  import ReactGA from "react-ga";

  function App() {
    const aboutRef = useRef(null);
    const skillsRef = useRef(null);
    const projectRef = useRef(null);
    const contactRef = useRef(null);

    useEffect(() => {
      ReactGA.initialize('G-1B4LV0R4RZ');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
      <>
        <Navbar aboutRef={aboutRef} skillsRef={skillsRef} projectRef={projectRef} contactRef={contactRef} />
        <Animation />
        <Project ref={projectRef} />
        <Skills ref={skillsRef} />
        <ContactUs ref={contactRef} />
        <ResumeDownloadButton/>
        <Footer />
        <Analytics />
      </>
    );
  }

  export default App;
