import React, { useState, useEffect } from 'react';
import './animation.css'; // Assuming you have your CSS file for styling
import img1 from './img1.jpg';
import About from './about';
import ReactGA from "react-ga";


const Animation = () => {

  const [text, setText] = useState('');
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const sentences = ["!Hi I am Divyanshu Gupta ", "II am a Web developer  "];
  const speed = 100; // Speed of typing in milliseconds


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    let currentCharacterIndex = 0;

    const typingInterval = setInterval(() => {
      if (currentCharacterIndex === sentences[currentSentenceIndex].length) {
        // Move to the next sentence after typing completes
        clearInterval(typingInterval);
        setTimeout(() => {
          setText('');
          setTimeout(() => {
            setCurrentSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
          }, 1000); // Delay before starting to type the next sentence
        }, 1000); // Delay before clearing the text
        return;
      }

      setText(prevText => prevText + sentences[currentSentenceIndex].charAt(currentCharacterIndex));
      currentCharacterIndex++;
    }, speed);

    return () => clearInterval(typingInterval);
  }, [currentSentenceIndex]);

  // Set the first letter of the first sentence when the component mounts
  useEffect(() => {
    setText(sentences[0].charAt(0));
  }, []);


  return (
    <div className=' typewriter-container'>
    <div className="relative-container">
      <img className="img-fluid max-width-100 background-image" src={img1} alt="img" />
      <div className="typewriter-text">{text}</div>
      <div className="about-text">
        <About />
      </div>
    </div>
  </div>
  );
};

export default Animation;
